import classes from './CircleProgress.module.css'

const CircleProgress = ({value}) => {

    const color = value > 49.5 ? 'green' : '#fb5456'

  return (

    <div className={classes.Card}>
        <div className={classes.Percent}>
        <svg>
            <circle cx="35" cy="35" r="30" style={{stroke:color }} ></circle>
            <circle cx="35" cy="35" r="30" style={{'--percent': value ,  stroke:color}}></circle>
        </svg>
        <div className={classes.Number}>
            <h3>{value}<span>%</span></h3>
        </div>
        </div>
    </div>
   
  );
}

export default CircleProgress;
