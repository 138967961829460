export const colors = {
  maincolor: '#FB5456',
  font: '#434343',
  font2: '#737373',
  inputback: '#FAFAFA',
  infofont: '#3B71B0',
  orange: '#FFB448',
  selected: '#FED21A',
  brown: '#D38B23',
  yellow: '#F8EEDE',
  gray: '#AFAFAF',
  red: '#A5243D',
  lightred: '#FFEEEE',
  blue: '#07B2BD',
  gold: '#FED21A',
  lightorange: '#E2C0C0',
  darkGreen: '#39B970',
  lightGray: '#8B8D9F',
  whiteGray: '#B3B5C5',
  green: '#07B2BD',
  vLightGray: '#BCBCBC',
  darkOrange: '#FE5C36',
  regularGray: '#d9d9d9',
  white: '#ffff',
  black: 'black',
  darkRed: '#E54335',
  whiteBlue:'#e5f7f8',
};
