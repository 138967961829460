import {useEffect, useState} from 'react';
import {Col, Container, Row, Modal, Image, Nav} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ShowUserProfile.module.css';
import {
  deleteAccount,
  
  getReferalCode,
  getUserData,
} from './Auth.service';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  FaCamera,
  FaCheckCircle,
  FaPlusSquare,
  FaSignOutAlt,
} from 'react-icons/fa';
import {Language} from './interface/ILanguages';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {colors} from '../../constants/colors';
import toast, {Toaster} from 'react-hot-toast';
import UserSessions from '../Sessions/UserSessions';
import PrivateSessionsList from '../Booking/PrivateSessionsList';
import ConsultantProfileCourses from '../Consultants/components/ConsultantProfileCourses';
import {getUserAvatar, isUserLoggedIn} from '../../auth/utiles';
import {NavLink} from 'react-router-dom';
import {setLoading} from '../../store/userSlice';
import UserSurveyList from '../Survey/UserSurveyList';
import UserCertificates from '../Courses/UserCertificates';
import MyCourses from '../Consultants/components/MyCourses';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserFamily from './UserFamily';
import { addMembers, editMembers } from './family.services';

const ChildForm = () => {
  const { id } = useParams()
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
 
  const [disableInputs, setdisableInputs] = useState(queryParams.get('status') == 'add' ? false : true);
  const [name, setName] = useState('');
  const [birth, setBirth] = useState('');
  const [gender, setGender] = useState(1);
  const [type, setType] = useState(1);
  const [refCode, setRefCode] = useState('');
  const [avatar, setAvatar] = useState('');
  
  const [imagePreview, setImagePreview] = useState();
  const [errors, setErrors] = useState<{[key: string]: string}>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [status, setStatus] = useState(queryParams.get('status') == 'add' ? 'add' : 'edit');
 
  
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setAvatar(e.target.result);
        setImagePreview(file);
      };

      reader.readAsDataURL(file);
    }
  };

 

  
  useEffect(() => {
    if( queryParams.get('status') == 'edit' ){
      const data = location.state.data
      console.log('child dataaa' , data )
      setName(data.username)
      setGender(data.gender)
      setType(data.relation)
      setBirth(data.birth_date)
      if( data.avatar ){
        setAvatar(data.avatar);
      }
    }
    if( disableInputs ){
     
    }
  }, []);

  const getData = async (redirectPage = false) => {
    let result = await getUserData();
    console.log('result.response', result.response);
    if (result.response.status) {
      setName(result.response.user.username);
      setBirth(result.response.user.birth_date);
      setGender(result.response.user.gender);
      setAvatar(result.response.user.avatar);
      
      //change image profile in local storage after edit profile
      if (redirectPage) {
        localStorage.setItem('avatar', result.response.user.avatar);
        dispatch(setLoading(false));
      }
    }
  };

  

 

  
  const Apply = async () => {
    
    let error: {[key: string]: string} = {};
    let y = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // let phoneCheck =
    //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    // if (
    //   phoneCheck.test(
    //     `${this.state.phone_code}${this.containZero(this.state.phone)}`
    //   ) == false &&
    //   this.state.phone != "" &&
    //   this.state.phone != null
    // )
    //   errors.phone = I18n.t("wrongphone");

    if (name == ''){
      error.name = 'Name Invalid';
    }else if( birth == '' ){
      error.name = 'Birth Date Invalid';
    }
   

    
   

    setErrors(error);
    if (Object.keys(error).length === 0) {
      dispatch(setLoading(true));
      let data = new FormData();
      if( queryParams.get('status') == 'edit' ){
        data.append('_method', 'put');
      }
      data.append('username', name);
      data.append('relation', String(type));
      data.append('gender', String(gender));
      data.append('birth_date', birth);
     
      if (imagePreview) data.append('avatar', imagePreview);
      

      let result = queryParams.get('status') == 'add' ? await addMembers(data) : await editMembers(data,queryParams.get('id')) ;
      if (result.response) {
        console.log('result.response' , result.response )
        dispatch(setLoading(false));
        navigate(`/show/user/profile?tab=family`)
      }

    }
  };
 
  return (
    <Container>
      <Toaster position="top-right" />
      <Row className="d-flex justify-content-between">
        <Col xs={12}>
          <div>
            <h3
              className={`fontWeight-bold ${classes.HeaderTitle} text-center mt-4`}>
              { status == 'add' ? 'Add' : 'Edit' } Child
            </h3>

            <div className="mt-5 avatar-uploader text-center">
              <Image
                src={avatar ? avatar : '/kid.png'}
                alt="Avatar Preview"
                roundedCircle
                width={150}
                height={150}
              />
              <label
                htmlFor="avatar-input"
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: 'auto',
                  padding: '10px',
                  display: 'block',
                  color: '#fff',
                  cursor: 'pointer',
                }}>
                {!disableInputs && (
                  <div className="default-avatar">
                    <div
                      style={{
                        background: '#FB5456',
                        padding: '7px 17px',
                        borderRadius: '10px',
                        display: 'flex',
                      }}>
                      <FaCamera color="#fff" size={23} />
                      <p style={{margin: '0 5px'}}>
                        Change Picture
                      </p>
                    </div>
                  </div>
                )}
              </label>
              <input
                type="file"
                id="avatar-input"
                accept="image/*"
                onChange={handleImageChange}
                style={{display: 'none'}}
                disabled={disableInputs}
              />
            </div>

            <Row>
              <Col md={6}>
                <div className="mt-3 mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    disabled={disableInputs}
                    className={`form-control m-0 ${classes.FormInput}`}
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Gender</label>
                  <div className={`${classes.CheckBoxesContainer} d-flex`}>
                  
                      <p className={`${classes.CheckBox}`} style={{ margin: '5px 40px 0px 0' }}>
                        <input
                          type="radio"
                          id="male"
                          name="radio-group"
                          defaultChecked
                          disabled={disableInputs}
                          checked={gender == 1}
                          onChange={e => setGender(1)}
                        />
                        <label htmlFor="male">Male</label>
                      </p>
                      
                      <p className={`${classes.CheckBox}`} style={{ margin: '5px 40px 0px 0' }} >
                        <input
                          type="radio"
                          id="female"
                          name="radio-group"
                          checked={gender == 2}
                          disabled={disableInputs}
                          onChange={e => setGender(2)}
                        />
                        <label htmlFor="female">Female</label>
                      </p>
                  
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3 mb-3">
                  <label>Date Of Birth</label>
                  <input
                    type="date"
                    disabled={disableInputs}
                    className={`form-control m-0 ${classes.FormInput}`}
                    placeholder="DD-mm-yyyy"
                    required
                    value={birth}
                    onChange={e => setBirth(e.target.value)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Select Relation</label>
                  <div className={`${classes.CheckBoxesContainer} d-flex`}>
                  
                      <p className={`${classes.CheckBox}`} style={{ margin: '5px 40px 0px 0' }}>
                        <input
                          type="radio"
                          id="child"
                          name="radio-group-relation"
                          defaultChecked
                          disabled={disableInputs}
                          checked={type == 1}
                          onChange={e => setType(1)}
                        />
                        <label htmlFor="child">Child</label>
                      </p>
                      
                      <p className={`${classes.CheckBox}`} style={{ margin: '5px 40px 0px 0' }} >
                        <input
                          type="radio"
                          id="parent"
                          name="radio-group-relation"
                          checked={type == 2}
                          disabled={disableInputs}
                          onChange={e => setType(2)}
                        />
                        <label htmlFor="parent">Parent</label>
                      </p>
                  
                  </div>
                </div>
              </Col>

              {errors?.name && (
                    <Col md="12">
                    <p style={{color: colors.darkRed}}>
                      {errors.name}
                    </p>
                    </Col>
                  )}
              
            </Row>

            { status == 'add'
            
            ?

            <div
              className="mt-4 mb-2"
              onClick={() => {
                disableInputs
                  ? setdisableInputs(false)
                  : Apply();
              }}>
              <MainButton text={`Add`}
              />
            </div>
            :
            
             <div>

              <div
                className="mt-4 mb-2"
                onClick={() => {
                  disableInputs
                    ? setdisableInputs(false)
                    : Apply();
                }}>
                <MainButton
                  text={disableInputs ? 'Edit Profile' : 'Apply'}
                />
              </div>

              <div className="mt-4 mb-2">
                <MainButton
                  text="Delete Account"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
             </div>     
            

            

            }  
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChildForm;
