// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC3LcZSi5TydtHqA-H-a_qOkaFQgK1aEEs',
  authDomain: 'nurabi.firebaseapp.com',
  databaseURL: 'https://nurabi-default-rtdb.firebaseio.com',
  projectId: 'nurabi',
  storageBucket: 'nurabi.appspot.com',
  messagingSenderId: '304852451427',
  appId: '1:304852451427:web:3e05a4ecdae2e8d283b856',
  measurementId: 'G-CFFDVFGP4N',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
