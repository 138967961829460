import Card from 'react-bootstrap/Card';
import {FaCircle, FaPlay} from 'react-icons/fa';
import MainButton from '../../../../UI/main-button/MainButton';
import CancelButton from '../../../../UI/cancel-button/CancelButton';
import courseImg from './5218235.jpg';
import './CourseCard.css';
import {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom';

function CourseCard() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  function handleClick() {
    navigate('/lesson/1');
  }

  return (
    <>
      <Card className="course-details-card">
        <div className="card-img-container">
          <div className="card-img-container">
            <div className="overlay">
              <div className="icon-container">
                <FaPlay className="preview-icon" />
              </div>
            </div>
            <img className="card-img-top" alt="courseImg" src={courseImg} />
          </div>
        </div>
        <Card.Body className="course-body">
          <h5>Price</h5>
          <p>
            <span className="discount-price">39$</span>{' '}
            <span className="real-price">60$</span>{' '}
            <span className="discount-percentage">30%</span>
          </p>
          <h5>Include</h5>
          <div className="course-features">
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              15 Total Hours
            </p>
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              3 Quiz
            </p>
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              3 Assesmsnts
            </p>
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              Certificate
            </p>
            <p>
              <span>
                <FaCircle color="#fb5456" style={{fontSize: '12px'}} />{' '}
              </span>{' '}
              4 Files
            </p>
          </div>
          <div className="mt-4 mb-2" onClick={handleShow}>
            <MainButton text="Enroll Course" />
          </div>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="pop-up-des"
            style={{
              padding: '2rem',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              borderRadius: '10px',
            }}>
            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">Course Name</div>
              <h5 className="desc-value">How to make your son Hight levels</h5>
            </div>

            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">Provided By</div>
              <h5 className="desc-value">Nurabi</h5>
            </div>

            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">Course Includes</div>
              <h5 className="desc-value">list</h5>
            </div>

            <div className="desc-title-container">
              <div className="desc-title text-muted">Course Price</div>
              <h5 className="desc-value">0 $</h5>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={handleClick} className="w-100">
            <MainButton text="Enroll Course" />
          </div>
          <CancelButton text="Cancel" />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CourseCard;
