import axios from 'axios';


export const ErrorHandlingInterceptor = () => {
  axios.interceptors.response.use(function (response) {
    //console.log('Error Hanldle12',response);
    if (response.data.code === 201) {
    }
    return response;
  },  (error) =>{
    //console.log('Error Hanldle1',error.response);
   
    
    return Promise.reject(error);
  });
};
