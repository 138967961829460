import {
  FaShoppingCart,
  FaSignOutAlt,
  FaBars,
  FaGripLines,
  FaFile,
  FaHome,
  FaPhoneAlt,
} from 'react-icons/fa';
import {NavLink, useNavigate} from 'react-router-dom';
import './navbar.css';
import {isUserLoggedIn} from '../auth/utiles';
import {NavProp} from './user-navigation-header/INav';

const ICON_SIZE = 20;

function Navbar({visible, show}: NavProp) {
  const navigate = useNavigate();
  const isLoggedIn = isUserLoggedIn();
  const avatar = localStorage.getItem('avatar');
  const name = localStorage.getItem('name');

  const logout = () => {
    alert('1')
    localStorage.removeItem('Consid');
    localStorage.removeItem('stars');
    localStorage.removeItem('id');
    localStorage.removeItem('switch');
    localStorage.removeItem('isCons');
    localStorage.removeItem('lang');
    localStorage.removeItem('name');
    localStorage.removeItem('logged');
    localStorage.removeItem('avatar');
    localStorage.removeItem('ratingStars');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('feedBack');
    if(localStorage.getItem('i18nextLng') ){
      alert('2')
      localStorage.removeItem('i18nextLng');
    }
    
    
    
    window.location.replace('/');
  };

  return (
    <>
      <div className="side-menu-bar">
        <div className="mobile-nav">
          <button className="mobile-nav-btn" onClick={() => show(!visible)}>
            <FaBars size={24} />
          </button>
        </div>
        <nav className={!visible ? 'navbar' : ''}>
          <button
            type="button"
            className="nav-btn"
            onClick={() => show(!visible)}>
            {!visible ? <FaGripLines size={30} /> : <FaGripLines size={30} />}
          </button>
          <div>
            <NavLink className="logo" to="/">
              <img src="/nurabi.png" alt="logo" />
            </NavLink>
            <div className="links nav-top">
              <div className="user-profile d-flex align-items-center mb-4">
                <img
                  src={
                    avatar == 'null' || avatar == null
                      ? `/icons/manHolder.png`
                      : avatar!
                  }
                  alt="img"
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    marginRight: '15px',
                  }}
                />
                <div className="main-head">
                  <p className="m-0">{name?.slice(0, 22)}</p>
                  <p className="m-0">Welcome To Nurabi</p>
                </div>
              </div>

              <hr style={{color: '#9a9a9a'}} />
              <NavLink to="/" className="nav-link">
                <FaHome size={ICON_SIZE} color="#fb5456" />
                <span>Home</span>
              </NavLink>
              <NavLink to="/courses" className="nav-link">
                <FaFile size={ICON_SIZE} color="#fb5456" />
                <span>Courses </span>
              </NavLink>
              {/* <NavLink
                to="/orders"
                className="nav-link justify-content-between">
                <div>
                  <FaShoppingCart size={ICON_SIZE} color="#fb5456" />
                  <span>My Cart</span>
                </div>
                <span
                  style={{
                    background: '#fb5456',
                    color: '#fff',
                    padding: '5px 15px',
                    borderRadius: '10px',
                  }}>
                  0
                </span>
              </NavLink> */}

              <hr style={{color: '#9a9a9a'}} />

              <div className="main-head">
                <p className="m-0">Our Awesome App</p>
                <p>
                  Download our app from play store or apple store on your phone
                </p>
              </div>
              <div className="store-icons">
                <img
                  src="/icons/app-store.png"
                  className="mb-1"
                  style={{maxWidth: '170px'}}
                />
                <img
                  src="/icons/play-store.png"
                  className="mb-1"
                  style={{maxWidth: '170px'}}
                />
              </div>
            </div>
          </div>

          <hr style={{color: '#9a9a9a'}} />

          <div className="links">
            <NavLink to="/contact-us" className="nav-link">
              <FaPhoneAlt size={ICON_SIZE} color="#fb5456" />
              <span>Contact Us</span>
            </NavLink>
            {isLoggedIn && (
              <div onClick={() => logout()}>
                <NavLink to="#" className="nav-link">
                  <FaSignOutAlt size={ICON_SIZE} color="#fb5456" />
                  <span>Logout</span>
                </NavLink>
              </div>
            )}
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
