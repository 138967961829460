import React, {useState} from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import './MultiDateCalendar.css';
import moment from 'moment';

const MultiDateCalendar = ({daysSelected, closed}) => {
  let [values, setValue] = useState([]);

  function handleChange(values) {
    setValue(values);
    daysSelected(values);
  }

  return (
    <DatePicker
      multiple
      value={values}
      placeholder="Select"
      onChange={handleChange}
      inputClass="custom-input"
      format="YYYY-MM-DD"
      plugins={[<DatePanel />]}
      onClose={() => {
        closed();
      }}
    />
  );
};

export default MultiDateCalendar;
