import {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {NavLink, useNavigate,useLocation} from 'react-router-dom';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/login.module.css';
import {FaEye} from 'react-icons/fa';
import {LoginAccount, SocialLogin} from './Auth.service';
import {useGoogleLogin} from '@react-oauth/google';
import toast, {Toaster} from 'react-hot-toast';


const Login = () => {
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState<string>('');

  useEffect(() => {

    if( location.state && location.state.location ){
      setRedirectTo(`${location.state.location}`)
    }else{
      setRedirectTo('/')
    }
  }, []);


  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const Login = async () => {
    if (email == '' || password == '') {
      setError('Wrong Data');
    } else {
      let data = new FormData();
      data.append('email', email);
      data.append('password', password);
      let result = await LoginAccount(data);
      console.log('RET', result);
      console.log('logn res', result?.response);
      if (result?.response?.access_token) {
        localStorage.setItem('logged', '1');
        localStorage.setItem('token', result.response.access_token);
        localStorage.setItem('name', result.response.user.username);
        localStorage.setItem('email', result.response.user.email);
        localStorage.setItem('avatar', result.response.user.avatar);
        localStorage.setItem('id', result.response.user.id);
        localStorage.setItem('isCons', result.response.user.is_consultant);
        localStorage.setItem('switch', '0');
        localStorage.setItem(
          'Consid',
          result.response.user.consultation_info
            ? result.response.user.consultation_info.id
            : 0,
        );
        localStorage.setItem(
          'feedBack',
          result.response.user.consultation_info
            ? JSON.stringify(result.response.user.consultation_info?.feedBack)
            : JSON.stringify([]),
        );
        localStorage.setItem(
          'ratingStars',
          result.response.user.consultation_info
            ? JSON.stringify(
                result.response.user.consultation_info?.rating_stars,
              )
            : JSON.stringify([]),
        );
        localStorage.setItem(
          'stars',
          result.response.user.consultation_info
            ? result.response.user.consultation_info?.ratings
            : 0,
        );
        
        window.location.href = redirectTo;
        
      } else {
        if (result?.error?.response?.status == 400) {
          setError(result?.error?.response?.data?.msg);
        } else {
          setError('invalid Data');
        }
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log('tokenResponse', tokenResponse.access_token);

      // if (tokenResponse && tokenResponse?.accessToken) {
      console.log('tokenResponse2', tokenResponse.access_token);
      // Use the access token to fetch user details
      fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      })
        .then(response => response.json())
        .then(async user => {
          console.log(user);
          localStorage.setItem('email', user.email);
          localStorage.setItem('avatar', user.picture);
          let data = new FormData();
          data.append('provider', 'google');
          data.append('provider_id', user.id);
          data.append('email', user.email);
          // data.append('firebase_token', await gettoken());
          let result = await SocialLogin(data);
          console.log('resultresultresult', result.response);
          if (!result.response.success) {
            toast.error(result?.response?.message);
          } else if (result.response?.token) {
            localStorage.setItem('logged', '1');
            localStorage.setItem('token', result.response.token);
            localStorage.setItem('name', result.response.user?.username);
            localStorage.setItem('isCons', result.response.user.is_consultant);

            localStorage.setItem(
              'id',
              JSON.stringify(result.response.user?.id),
            );
            localStorage.setItem(
              'gender',
              result.response.user?.gender == 1 ? '1' : '2',
            );
            result.response.user?.avatar &&
              localStorage.setItem('avatar', result.response.user?.avatar);

            navigate('/');
          } else {
            navigate('/register', {
              state: {
                social: true,
                provider_id: user.id,
                provider: 'google',
                email: user.email,
              },
            });
          }
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
        });
    },
  });

  return (
    <div>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          <Col xs={{span: 12}} md={{span: 6}}>
            <div className={classes.FormPadding}>
              <div onClick={() => login()} className={classes.GoogleSignIn}>
                <img src="/icons/google.png" alt="img" /> Sign in with Google
              </div>

              <p className={`fontWeight-bold ${classes.HeaderTitle} `}>
                <span>Or Login By Email</span>
              </p>

              <div className="mt-3 mb-3">
                <input
                  type="email"
                  className={`form-control m-0 ${classes.EmailInput}`}
                  placeholder="Email"
                  required
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-3 mb-3" style={{position: 'relative'}}>
                <div
                  className={classes.togglePassword}
                  onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? (
                    <img src="/icons/close.png" alt="img" width={25} />
                  ) : (
                    <FaEye color="#08cad7" size={20} />
                  )}
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control m-0 ${classes.PasswordInput}`}
                  placeholder="Password"
                  required
                  onChange={e => setPassword(e.target.value)}
                />
                <div className={classes.error}>{error}</div>
                <p className={classes.forgetPassword}>
                  <NavLink
                    to="/forget-password"
                    style={({isActive}) => ({
                      color: 'rgb(75, 75, 75)',
                      fontWeight: '500',
                    })}
                    className="mr-auto">
                    Forget Password?
                  </NavLink>
                </p>
              </div>
              <div className="mt-4 mb-2">
                <MainButton text="Login" onClick={Login} />
              </div>

              <div className="col-12 text-center">
                <p className="mb-0 mt-3">
                  <small className="text-dark mr-2">
                    You Don't have an account?
                  </small>
                </p>
                <NavLink
                  to="/sign-up"
                  className={`text-primary font-weight-bold ${classes.CreateOne} `}>
                  {' '}
                  Create New Account
                </NavLink>
              </div>
            </div>
          </Col>

          <Col xs={6}>
            <img
              src="/login.png"
              alt="img"
              className={classes.LoginBackground}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
