import {Container, Row, Col} from 'react-bootstrap';
import Header from '../../components/bankQuestions/Header';
import classes from './style/BankQuestions.module.css';
import {Fragment, useEffect, useState} from 'react';
import {getBankQuestions} from './Bank.service';
import BankQuestionsList from './BankQuestionsList';
import {useDispatch} from 'react-redux';
import {setLoading} from '../../store/userSlice';
import { isUserLoggedIn } from '../../auth/utiles';
import {useNavigate,useLocation} from 'react-router-dom';

const BankQuestions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  useEffect(() => {

    const Logged = isUserLoggedIn();
    if( Logged ){
      dispatch(setLoading(true));
      getBank();
    }else{
      navigate(`/login`, {state: {location: location.pathname }})
    }

  }, []);

  const getBank = async () => {
    let data = new FormData();
    search.length != 0 && data.append('question', search);
    let result = await getBankQuestions(data);
    if (result.response) {
      console.log('get banck' , result.response)
      setQuestions(result.response.data);
      dispatch(setLoading(false));
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      getBank();
    }
  };

  return (
    <div>
      <Container className="text-center">
        <Row>
          <Col md={7} className="m-auto">
            <Row className="mb-4">
              <Col xs={12}>
                <h1 className="page-title pb-0">Bank Questions</h1>
                <p style={{fontSize: '21px'}}>
                  Check all questions that help you
                </p>
              </Col>
              {/* <Col xs={9}> */}
              <input
                type="text"
                placeholder="Search Key Word"
                onChange={e => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
                className={` ${classes.bankQuestionSearch} form-control m-0 `}></input>
              {/* </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
      <Fragment>
        <div className="content-details">
          <Container>
            <BankQuestionsList Questions={questions} loadQuestions={getBank} />
          </Container>
        </div>
      </Fragment>
    </div>
  );
};

export default BankQuestions;
