import ShareIcon from "../../components/share-icon/ShareIcon";
import { useAppDispatch } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { useState , useEffect } from "react";
import { setLoading } from "../../store/userSlice";
import { Col, Container, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { colors } from "../../constants/colors";
import { getDomain } from "../../helpers/getDomain";
import axios from "axios";
const UserCertificates = () => {
 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  

  useEffect(() => {
    getData()
  }, []);

  
  const getData = async () => {
    dispatch(setLoading(true));
    let token = await localStorage.getItem('token');
    console.log('token', token);
    try {
      
      var config = {
        method: 'get',
        url: getDomain() + `/api/mobile/user/courses/certificates`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await axios(config);

      console.log('klk', result.data.data);

      if (result.data) {
        dispatch(setLoading(false));
        console.log('certs' , result.data);
        setData(result.data.data)
      } else {
        dispatch(setLoading(false));
        console.log('eer', result);
      }
    } catch (e) {
      console.log({e});
      
    }
  };
  

  useEffect(() => {
    
  }, []);

  
  return (
    <Container className="mb-5 mt-4">
      <Row>

      

      {data.map( (item) => {
              return (
                <Col md="6" className="mb-3">
                    <div style={{
                        background: '#fff',
                        paddingBottom : '20px',
                        borderRadius: '10px',
                        border: '1px solid rgba(0,0,0,.125)',
                        }}>
                    <img src={item.certificate}
                    style={{
                        width: '100%' ,
                        height: 'calc(100vw * .20)',
                        borderRadius: '10px 10px 0 0',
                        }}
                       
                        />
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <h6 style={{ margin: '0 10px' }}>{item.course_title}</h6>
                        <div className="d-flex align-items-center">
                            <FaDownload 
                                style={{
                                color:colors.maincolor ,
                                background:colors.lightred,
                                fontSize:'50px',
                                borderRadius: '10px',
                                padding: '12px',
                                margin: '0 10px',
                                cursor:'pointer'
                                }}
                                onClick={ () => {
                                    window.open(item.certificate, '_blank')
                                } } 
                            />
                            {/*<ShareIcon url={item.certificate} />*/}
                        </div>
                    </div>
                    </div>
                </Col>

                
              );
            })}



            {
              data.length == 0 && (
                <div className='text-center'>
                  <img src="/nurabi.png"  width={70}/>
                  <h2 className='mt-3'>No Records Found</h2>
                </div>
              )
            }

      
      </Row>
    </Container>
  );
};

export default UserCertificates;
