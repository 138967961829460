import {Container, Row} from 'react-bootstrap';
import RateAndPrice from '../RateAndPrice/RateAndPrice';
import './LiveCourseSections.css';
import LiveCourseContent from '../live-course-content/LiveCourseContent';

const LiveCourseSections = () => {
  return (
    <div>
      <Container>
        <Row className="d-flex justify-content-between">
          <h3 className="course-title">Live Course</h3>
          <p className="course-description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard Lorem Ipsum
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard
          </p>
          <h4 className="course-provider">By Nurabi</h4>

          <RateAndPrice />

          <LiveCourseContent />
        </Row>
      </Container>
    </div>
  );
};

export default LiveCourseSections;
