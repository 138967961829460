import {Container} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import classes from './PersonalityTestQuestion.module.css';
import {useState} from 'react';
// import UserQuizResult from '../user-quiz-result/UserQuizResult';

const PersonalityTestQuestion = () => {
  const quiz = {
    topic: 'Javascript',
    level: 'Beginner',
    totalQuestions: 4,
    perQuestionScore: 5,
    questions: [
      {
        question:
          'Which function is used to serialize an object into a JSON string in Javascript?',
        choices: ['stringify()', 'parse()', 'convert()', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'stringify()',
      },
      {
        question:
          'Which of the following keywords is used to define a variable in Javascript?',
        choices: ['var', 'let', 'var and let', 'None of the above'],
        type: 'MCQs',
        correctAnswer: 'var and let',
      },
      {
        question:
          'Which of the following methods can be used to display data in some form using Javascript?',
        choices: [
          'document.write()',
          'console.log()',
          'window.alert',
          'All of the above',
        ],
        type: 'MCQs',
        correctAnswer: 'All of the above',
      },
      {
        question: 'How can a datatype be declared to be a constant type?',
        choices: ['const', 'var', 'let', 'constant'],
        type: 'MCQs',
        correctAnswer: 'const',
      },
    ],
  };

  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  const {questions} = quiz;
  const {question, choices, correctAnswer} = questions[activeQuestion];

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    setResult(prev =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
          }
        : {...prev, wrongAnswers: prev.wrongAnswers + 1},
    );
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion(prev => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index);
    if (answer === correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };

  const addLeadingZero = number => (number > 9 ? number : `0${number}`);
  return (
    <div>
      {!showResult ? (
        <div>
          <Container>
            {/* personality analysis list     */}
            <section className={`section ${classes.personalityTest}`}>
              <div>
                <h3 className="mb-5">{question}</h3>
              </div>

              <div className={classes.Questions}>
                {choices.map((answer, index) => (
                  <p
                    onClick={() => onAnswerSelected(answer, index)}
                    key={answer}
                    className={classes.Question}>
                    <input
                      type="radio"
                      id={`answer-${index}`}
                      name="radio-group"
                    />
                    <label htmlFor={`answer-${index}`}>{answer}</label>
                  </p>
                ))}
              </div>
            </section>
          </Container>

          <div
            className={`d-flex justify-content-between align-items-center ${classes.QuestionFooter}`}>
            <span className="d-none">{result}</span>
            <p className="m-0">
              {addLeadingZero(activeQuestion + 1)} Of{' '}
              {addLeadingZero(questions.length)} Questions
            </p>
            <div onClick={onClickNext} className="d-flex align-items-center">
              <p
                style={{
                  color: '#fb5456',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  margin: '0 3rem',
                }}>
                Skip
              </p>

              <Button
                disabled={selectedAnswerIndex === null}
                className={classes.NextButton}
                variant="primary">
                {activeQuestion === questions.length - 1
                  ? 'Finish'
                  : 'Continue'}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        // <UserQuizResult />
        <p></p>
      )}
    </div>
  );
};

export default PersonalityTestQuestion;
