import {Col, Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ContactUs.module.css';
import {useState} from 'react';
import {ContanctNurabi} from '../Consultants/Consultants.service';
import toast, {Toaster} from 'react-hot-toast';

const ContactUs = () => {
  const [message, setMessage] = useState('');

  const contactUsFn = async (message: string) => {
    if (message != '') {
      let data = new FormData();
      data.append('message', message);
      try {
        let result = await ContanctNurabi(data);
        console.log(result);

        if (result.response) {
          toast.success('Done');
          setMessage('');
        }
      } catch (e) {}
    }
  };
  return (
    <div>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          <Col xs={6}>
            <div className={classes.FormPadding}>
              <h3 className={`fontWeight-bold ${classes.HeaderTitle} `}>
                Contact Us
              </h3>

              <div className="mt-3 mb-3">
                <label>Message</label>
                <Form.Control
                  className={`form-control m-0 ${classes.TextArea}`}
                  as="textarea"
                  rows={5}
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                />
                <p className={classes.ValidationMessage}>
                  Enter At Least 30 Character
                </p>
              </div>

              <div className="mt-4 mb-2">
                <MainButton text="Send Message" onClick={contactUsFn} />
              </div>

              <div className={`${classes.Contacts} text-center`}>
                <h6>Get In Touch With Us</h6>
                <div className={`d-flex justify-content-center`}>
                  {/* <a href="/" target="_blank">
                    <img
                      src="/social/telephone-call.png"
                      alt="telephone-call"
                    />
                  </a> */}
                  <a href="https://wa.me/+16478059910" target="_blank">
                    <img src="/social/whatsapp.png" alt="whatsapp" />
                  </a>
                  <a href="mailto:info@nurabi.net" target="_blank">
                    <img src="/social/gmail.png" alt="gmail" />
                  </a>
                </div>

                <h6>Social Media</h6>
                <div className={`d-flex justify-content-center`}>
                  <a href="https://twitter.com/NurabiApp" target="_blank">
                    <img src="/social/twitter.png" alt="twitter" />
                  </a>
                  <a
                    href="https://www.facebook.com/NurabiApp.EN"
                    target="_blank">
                    <img src="/social/facebook.png" alt="facebook" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/nurabiapp"
                    target="_blank">
                    <img src="/social/linkedin.png" alt="linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={6}>
            <img src="/login.png" alt="img" className={classes.Background} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
