import consultantImg from '../style/consultant.jpg';
import '../style/ConsultantCard.css';
import {useNavigate} from 'react-router-dom';
import {ConsultantItemProps} from '../interfaces/IConsultants';
import classes from '../style/ConsultantCardHorizontal.module.css';
import {truncate} from '../../../helpers/truncate';

function ConsultantCardHorizontal({consultant}: ConsultantItemProps) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(
      `/consultants/${consultant?.consultation_info_type}/${consultant?.id}`,
    );
  }
  const fields = consultant?.consultation_info_fields.slice(0, 2);
  return (
    <div className={classes.Item} onClick={handleClick}>
      <span
        className={`course-type ${
          consultant?.consultation_info_type == 'coach'
            ? 'live-course-type'
            : 'recorded-course-type'
        }`}>
        {consultant?.consultation_info_type == 'organization'
          ? 'coach'
          : consultant?.consultation_info_type}
      </span>
      <h5 className="mt-2 mb-4">{consultant?.username}</h5>
      <div className="d-flex ">
        <div className={`${classes.Image}`}>
          <img src={consultant?.avatar ?? consultantImg} />
        </div>

        <div style={{flexGrow: '1', padding: '0 1rem'}}>
          <div>
            <h5 className="secondry-color mb-3" style={{fontSize: '17px'}}>
              {fields?.map((l, i) => (
                <span key={i}>
                  {l.title} {i + 1 < fields?.length ? ' . ' : ''}
                </span>
              ))}
            </h5>
          </div>
          <div
            className={`${classes.Languages} d-flex mb-1 align-items-center`}>
            {consultant?.consultation_info_languages &&
              consultant?.consultation_info_languages.length > 0 && (
                <div>
                  {consultant?.consultation_info_languages
                    .slice(0, 2)
                    .map((l, i) => (
                      <span key={i}>{l.name}</span>
                    ))}
                </div>
              )}
            {consultant?.consultation_info_languages &&
              consultant?.consultation_info_languages.length > 2 && (
                <p>+{consultant?.consultation_info_languages.length - 2}</p>
              )}
          </div>
          <div className="mb-1">
            <span className={`${classes.Availabilty} mt-2`}>
              {consultant?.available_sessions} available
            </span>
          </div>
          {consultant?.first_session_free != 0 && (
            <p>
              <span className={`${classes.Availabilty} mt-2`}>
                First Session Free
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConsultantCardHorizontal;
