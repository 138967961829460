import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ForgetPassword.module.css';
import {useState} from 'react';
import {ForgetPassAccount} from './Auth.service';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [errorMail, setErrorMail] = useState<string>('');

  const check = async () => {
    let y = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setErrorMail('');
    if (email == '') {
      setErrorMail('Please enter a valid email');
    } else if (y.test(email) == false) {
      setErrorMail('Please enter a valid email');
    } else {
      let data = new FormData();
      data.append('email', email);
      let result = await ForgetPassAccount(data);
      console.log('result.response', result.response);

      if (result.response.status) {
        navigate('/verify', {state: {email: email, forgetPass: true}});
      } else {
        setErrorMail(result.response.msg);
      }
    }
  };
  return (
    <div>
      <Container className={classes.FormContainer}>
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={6} >
            <div className={classes.FormPadding}>
              <h3 className={`fontWeight-bold ${classes.HeaderTitle} `}>
                Forget Password
              </h3>
              <p className={`${classes.HeaderPargraph}`}>
                Enter Your Email Address To Confirm
              </p>

              <div className="mt-3 mb-3">
                <label className="mb-2">Email</label>
                <input
                  type="text"
                  className={`form-control m-0 ${classes.Input}`}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
                <div className={classes.error}>{errorMail}</div>
              </div>

              <div className="mt-4 mb-2">
                <MainButton text="Confirm" onClick={check} />
              </div>
            </div>
          </Col>

          <Col xs={12} lg={6}>
            <img
              src="/login.png"
              alt="img"
              className={classes.LoginBackground}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword;
