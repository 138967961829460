export const getChatDomain = () => {
  
    let chatDomainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'predevwebapp.nurabi.live' ){
        chatDomainName = 'DEV'
    }

    else if( hostName == 'webapptesting.nurabi.live' ){
        chatDomainName = 'TEST'
    }

    else if( hostName == 'web.nurabi.net' || hostName == 'nurabi.net' ){
        chatDomainName = 'Chats'
    }
    
    else{
        chatDomainName = 'DEV'
    }
    
    
    return chatDomainName
  
};
