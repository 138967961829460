export const getDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'predevwebapp.nurabi.live' ){
        domainName = 'https://development.nurabi.live'
        //domainName = 'https://nurabi.live'
        
    }

    else if( hostName == 'webapptesting.nurabi.live' ){
        domainName = 'https://nurabi.live'
    }

    else if( hostName == 'web.nurabi.net' || hostName == 'nurabi.net' ){
        domainName = 'https://api.nurabi.net'
    }
    
    else{
        domainName = 'https://development.nurabi.live'
    }
    
    
    return domainName
  
};
