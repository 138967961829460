import {Col, Container, Row} from 'react-bootstrap';
import {useLocation, useNavigate, useNavigationType, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';

import { useAppDispatch } from '../hooks/hooks';
import { setLoading } from '../store/userSlice';
import { getSurveyMetting } from './Consultants/Consultants.service';
import RateStarsEdit from '../UI/rate-stars-edit/RateStarsEdit';
import { submitSurvey } from './Sessions/Sessions.service';
import MainButton from '../UI/main-button/MainButton';



const SurveyMeeting = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [surveyItems, setSurvetItems] = useState([]);

  const [editedSurveyItems, setEditedSurveyItems] = useState([]);

  const getData = async (id:any) => {
   
    dispatch(setLoading(true));

    try {
     
      let result = await getSurveyMetting(id);
      if (result) {
        console.log('ss qq' , result.response.data.questionnaire.questionnaire_questions)
        setSurvetItems(result.response.data.questionnaire.questionnaire_questions);
        setEditedSurveyItems( result.response.data.questionnaire.questionnaire_questions);
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  const changeQuestionRate = (e: any, i: any) => {
    console.log(e, i);
    let items: any = editedSurveyItems;
    items[i].rateValue = e;
    console.log('items', items);
    setEditedSurveyItems(items);
  };

  const setSurvey = async () => {
    dispatch(setLoading(true));

    try {
      let data = new FormData();
      editedSurveyItems?.map((i: any, key) => {
        data.append(
          `questionnaires[${key}][questionnaire_id]`,
          i?.questionnaire_id,
        );
        data.append(`questionnaires[${key}][questionnaire_question_id]`, i?.id);
        data.append(`questionnaires[${key}][rate]`, i?.rateValue ?? 0);
      });
      data.append(`type_id`, String(id));
      data.append(`type`, 'ConsultationSession');
      let result = await submitSurvey(data);
      console.log('resultssss', result);

      if (result.response) {
        dispatch(setLoading(false));
        navigate('/show/user/profile?tab=sessions')
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  useEffect(() => {
    getData(id)
  }, []);



  return (
   <Container className='mt-5 mb-5'>
    <Row>
      <Col md={{span: 6, offset: 3}} >
      <div className="text-center pt-2 p-4">
            <div className="mb-3">
              <img src="/nurabi.png" width={60} />
            </div>

            {surveyItems.map((i: any, index) => {
              return (
                <div>
                  {i.question}
                  <RateStarsEdit
                    ratingChanged={(e: any) => {
                      changeQuestionRate(e, index);
                    }}
                  />
                </div>
              );
            })}

            <div
              onClick={() => {
                setSurvey();
              }}
              className='mt-4'
              >
              <MainButton text={'Submit Result'} />
            </div>
          </div>
      </Col>
    </Row>
   </Container>
  );
};

export default SurveyMeeting;
