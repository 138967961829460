import {Col, Container, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './style/UserTrackingSystems.module.css';
import TrackingCard from './components/TrackingCard';
import {useAppDispatch} from '../../hooks/hooks';
import {useEffect, useState} from 'react';
import {setLoading} from '../../store/userSlice';
import {getTrackingsList} from './Trackings.service';
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import {TrackingType} from './interfaces/ITrackings';

const UserTrackingSystems = () => {
  const dispatch = useAppDispatch();

  const [trackings, setTrackings] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [isMine, setIsMine] = useState<number>(1);

  useEffect(() => {
    getData(isMine);
  }, [page]);

  const getData = async (isMine: number) => {
    //setPage(page)
    dispatch(setLoading(true));
    let result = await getTrackingsList(page, isMine);
    if (result.response) {
      setTrackings(result.response.data);
      SetLastPage(result.response.meta.last_page);
    }
    dispatch(setLoading(false));
  };

  const handleSelect = (key: string | null) => {
    setPage(1);
    if (key == 'userTackingSystems') {
      setIsMine(1);
      getData(1);
    } else {
      setIsMine(0);
      getData(0);
    }
  };

  return (
    <Container>
      <h1 className="page-title">Follow-up Systems</h1>

      {/* User Courses Tabs */}
      <Tabs
        defaultActiveKey="userTackingSystems"
        id="uncontrolled-tab-example"
        className="custom-web-tabs mb-5"
        onSelect={(key: string | null) => handleSelect(key)}>
        <Tab eventKey="userTackingSystems" title="My follow up systems">
          <Row>
            {trackings.map((tracking: TrackingType) => {
              return (
                <Col lg={6}>
                  <TrackingCard
                    tracking={tracking}
                    isPackage={tracking.in_package == 1}
                    enrolled={true}
                  />
                </Col>
              );
            })}
          </Row>

          <div className="pagination-container">
            <ItemsPagination
              pages={lastPage}
              current={page}
              changePage={page => {
                setPage(page);
              }}
            />
          </div>
        </Tab>
        <Tab eventKey="nurabiTrackingSystems" title="Discover Systems">
          <Row>
            {trackings.map((tracking: TrackingType) => {
              return (
                <Col lg={6}>
                  <TrackingCard
                    tracking={tracking}
                    isPackage={tracking.in_package == 1}
                    enrolled={false}
                  />
                </Col>
              );
            })}
          </Row>

          <div className="pagination-container">
            <ItemsPagination
              pages={lastPage}
              current={page}
              changePage={page => {
                setPage(page);
              }}
            />
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default UserTrackingSystems;
