import {useEffect, useState} from 'react';
import {CheckBoxTitle} from '../../../UI/check-box-title/CheckBoxTitle';
import '../style/ConsultantFilter.css';
import {Col} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {insertLanguages} from '../../../store/LanguageSlice';
import {getFields} from '../Consultants.service';
import {Fields} from '../interfaces/IFields';
import {FilterProps} from '../interfaces/IConsultants';
import MultiDateCalendar from '../../../components/MultiCalendar/MultiDateCalendar';
import TimePickerContainer from '../../../components/TimePicker/TimePicker';

const FilterOptions = ({
  type,
  setLang,
  setField,
  setDays,
  setTimes,
  setTypes,
}: FilterProps) => {
  const dispatch = useAppDispatch();
  const languages = useAppSelector(state => state.language.languages);

  const types: string[] = ['consultant', 'organization'];
  const firstSearch: string[] = ['consultant', 'organization'];

  const [typesSelected, setTypesSelected] = useState<string[]>([]);
  const [fields, setFields] = useState<Fields[]>([]);
  const [fieldsSelected, setFieldsSelected] = useState<{[key: string]: any[]}>(
    {},
  );
  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [selectedTime, setSelectedTime] = useState<number | undefined>();
  const [languagesSelected, setLanguagesSelected] = useState<number[]>([]);
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [filterTime, setFilterTime] = useState<boolean>(false);

  const getListFields = async () => {
    console.log('changeeeeed');

    let typeFields: string[] =
      typesSelected.length == 0 ? firstSearch : typesSelected;

    let typeText: string = typeFields.join(',');
    console.log('changeeeeed', typeText);
    let result = await getFields(type == 'teachers' ? 'teacher' : typeText);
    console.log('changeeeeed2', result.response);

    if (result.response) {
      let data = result.response.data;
      setFields(data);
    }
  };
  useEffect(() => {
    dispatch(insertLanguages());
  }, []);

  useEffect(() => {
    getListFields();
  }, [typesSelected]);

  return (
    <Col md={3} className='filter-container' id='filterContainer' >
      <p className="filters-title">Filter</p>

      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Avalibile Sessions</p>
          <div className="filter-type-options calendarview">
            <Col className="mb-1">
              <CheckBoxTitle
                id={'calendar'}
                checked={filterDate}
                title={'Date'}
                setChecked={() => {
                  if (filterDate) {
                    setDays(null);
                  }
                  setFilterDate(!filterDate);
                }}
              />
            </Col>
            {filterDate && (
              <Col className="my-2">
                <MultiDateCalendar
                  daysSelected={(i: any[]) => {
                    setSelectedDays(i);
                  }}
                  closed={() => {
                    setDays(selectedDays);
                  }}
                />
              </Col>
            )}
          </div>

          <div className="filter-type-options mt-1 timeview">
            <Col className="mb-2">
              <CheckBoxTitle
                id={'calendar'}
                checked={filterTime}
                title={'Start Time'}
                setChecked={() => {
                  if (filterTime) {
                    setTimes(null);
                  }
                  setFilterTime(!filterTime);
                }}
              />
            </Col>
            {filterTime && (
              <Col>
                <TimePickerContainer
                  timeSelected={(i: number) => setSelectedTime(i)}
                  closed={() => {
                    setTimes(selectedTime);
                  }}
                />
              </Col>
            )}
          </div>
        </div>
      </div>
      {type != 'teachers' && (
        <div className="filter-content">
          <div className="filter-type">
            <p className="filter-type-title">Type</p>
            <div className="filter-type-options">
              {types.map(i => {
                return (
                  <CheckBoxTitle
                    id={i}
                    checked={typesSelected.includes(i)}
                    title={i == 'organization' ? 'coach' : i}
                    setChecked={() => {
                      if (typesSelected.includes(i)) {
                        setTypesSelected(
                          typesSelected.filter(item => item != i),
                        );
                        setTypes(typesSelected.filter(item => item != i));
                      } else {
                        let data = [...typesSelected];
                        data.push(i);
                        setTypesSelected(data);
                        setTypes(data);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Fields & Specialities</p>
          <div className="filter-type-options">
            {fields.map(i => {
              return (
                <div>
                  <CheckBoxTitle
                    id={i?.title}
                    checked={Object.keys(fieldsSelected).includes(
                      JSON.stringify(i?.id),
                    )}
                    title={i?.title}
                    setChecked={() => {
                      let selectedData: {[key: string]: any[]} = {
                        ...fieldsSelected,
                      };
                      let id = i?.id;

                      if (
                        !Object.keys(fieldsSelected).includes(
                          JSON.stringify(id),
                        )
                      ) {
                        selectedData[id!] = [];
                        setFieldsSelected(selectedData);
                        setField(selectedData);
                      } else {
                        delete selectedData[id!];
                        setFieldsSelected(selectedData);
                        setField(selectedData);
                      }
                    }}
                  />
                  {Object.keys(fieldsSelected).includes(
                    JSON.stringify(i?.id),
                  ) &&
                    i?.specialties.length != 0 &&
                    i?.specialties.map(item => {
                      return (
                        <div className="mx-3 my-2">
                          <CheckBoxTitle
                            id={item?.title}
                            checked={fieldsSelected[i.id!]?.includes(item.id)}
                            title={item?.title}
                            setChecked={() => {
                              let selectedData: {[key: string]: any[]} = {
                                ...fieldsSelected,
                              };

                              let id = item.id;
                              console.log({id});
                              if (
                                Object.keys(fieldsSelected).includes(
                                  JSON.stringify(i?.id),
                                )
                              ) {
                                if (selectedData[i?.id!].includes(id)) {
                                  let index = selectedData[i?.id!].findIndex(
                                    i => i == id,
                                  );
                                  selectedData[i?.id!].splice(index, 1);
                                  console.log({selectedData});
                                  setFieldsSelected(selectedData);
                                  setField(selectedData);
                                } else {
                                  selectedData[i?.id!].push(id);
                                  setFieldsSelected(selectedData);
                                  setField(selectedData);
                                }
                              }
                              console.log({fieldsSelected});
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Language</p>
          <div className="filter-type-options">
            {languages.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={languagesSelected.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    if (languagesSelected.includes(i.id)) {
                      setLanguagesSelected(
                        languagesSelected.filter(item => item != i.id),
                      );
                      setLang(languagesSelected.filter(item => item != i.id));
                    } else {
                      let data = [...languagesSelected];
                      data.push(i.id);
                      setLanguagesSelected(data);
                      setLang(data);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FilterOptions;
