import {
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import {FaCircle} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import MainButton from '../../../UI/main-button/MainButton';
import classes from './UserTrackingSystem.module.css';
import {NavLink, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {setLoading} from '../../../store/userSlice';
import {
  getTrackingsDetailsBeforeEnroll,
  enrollFree,
  addCart,
  getmemberForAssign,
  assignAndStartTrack,
} from '../../../Screens/Tracking/Trackings.service';
import {isUserLoggedIn, getUserId} from '../../../auth/utiles';
import {useNavigate} from 'react-router-dom';
import './VideoPlayer.css';
import CancelButton from '../../../UI/cancel-button/CancelButton';

const UserTrackingSystem = () => {
  const navigate = useNavigate();

  const isLoggedIn = isUserLoggedIn();

  const userId = getUserId() ?? '';

  const cartData = useAppSelector(state => state.cart.data);

  const {id} = useParams();

  const dispatch = useAppDispatch();

  const [tracking, setTracking] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const [enrolled, setEnrolled] = useState(false);

  const [show, setShow] = useState(false);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [members, setMembers] = useState([]);

  const [member, setMember] = useState(null);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    document.getElementById('video').play();
  };

  const existInCart = () => {
    return cartData.find(i => i.name == tracking.name);
  };

  const enroll = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append('amount', 1);
      data.append('user_id', userId);
      data.append('module', 'Tracking');
      data.append('module_id', id);
      let result = await enrollFree(data);
      if (result.response) {
        setEnrolled(true);
        dispatch(setLoading(false));
        setShow(false);
        navigate(`/tracking-system/${tracking.id}/details`, {
          state: {
            start_date: result.response.data[0].start_date,
            end_date: result.response.data[0].end_date,
            item: {
              ...result.response.data[0],
              user_owned_tracking_id: result.response.user_owned_tracking_id,
            },
          },
        });
      }
    } catch (e) {
      console.log({e});
    }
  };

  const addToCart = async () => {
    if (isLoggedIn) {
      dispatch(setLoading(true));
      try {
        let data = new FormData();
        data.append('model_id', String(id));
        data.append('model_name', 'Tracking');
        let result = await addCart(data);
        console.log('resultresultresultresultENROL', result);
        dispatch(setLoading(false));
      } catch (e) {
        console.log({e});
      }
    } else {
      navigate(`/login`);
    }
  };

  useEffect(() => {
    getData();
    getMembers();
  }, []);

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getTrackingsDetailsBeforeEnroll(id);
    if (result.response) {
      setTracking(result.response.data);
    }
    dispatch(setLoading(false));
  };

  const getMembers = async () => {
    let result = await getmemberForAssign(id);

    console.log('assign users', result.response.data);

    if (result.response?.data.length > 0) {
      setMembers(result.response.data);
    }
  };

  const getAssignedUserVal = e => {
    console.log(e.target.value);
    setMember(e.target.value);
  };

  const Assign = async () => {
    if (member) {
      console.log('data');
      console.log('id ', id);
      console.log(
        'tracking.user_tracking_enrollment_id',
        tracking.user_tracking_enrollment_id,
      );
      console.log('member ', member);
      let data = new FormData();
      data.append('tracking_id', id);
      if (tracking?.user_tracking_enrollment_id) {
        data.append(
          'user_tracking_enrollment_id',
          tracking.user_tracking_enrollment_id,
        );
      }
      data.append(`members[0]`, member);
      let result = await assignAndStartTrack(data);
      console.log('result', result);
      setShowAssignUsers(false);
    }
  };

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
      }}>
      Limited - Only you or a family member can start this regimen and you can
      not re-take it after you have finished it.
    </Popover>
  );

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col md={6} className={classes.TrackingDetails}>
            <div>
              <h1 className="main-title mb-4">{tracking?.name}</h1>

              <div className="mb-2">
                <div className="d-flex justify-content-between">
                  <div style={{fontSize: '1.2rem'}}>
                    {tracking?.price == 0 && 'Free'}
                    {tracking?.is_unlimited == 1 &&
                      tracking?.is_enrolled == 0 && (
                        <span
                          style={{fontWeight: '600'}}
                          className="text-dark mr-1 ml-1">
                          <img
                            src={'/icons/coin.png'}
                            alt="course-img"
                            style={{
                              marginRight: '10px',

                              verticalAlign: 'sub',
                            }}
                          />

                          <span>Limited</span>
                          <span> {tracking?.price} USD</span>
                          <span> - </span>
                          <span>Unlimited</span>
                          <span> {tracking?.unlimited_price} USD</span>
                        </span>
                      )}
                  </div>
                  <div>
                    <div className={classes.ToopTip}>
                      <ButtonToolbar>
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={popoverBottom}>
                          <img
                            src="/icons/info.png"
                            width={25}
                            style={{cursor: 'pointer'}}
                          />
                        </OverlayTrigger>
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <img
                  src={'/icons/clock.jpeg'}
                  alt="course-img"
                  style={{
                    marginRight: '10px',
                    width: '25px',
                    verticalAlign: 'sub',
                  }}
                />
                <span style={{fontSize: '1.2rem'}}>
                  {tracking?.duration} Days
                </span>
              </div>

              <div className="mb-3">
                <img
                  src={'/icons/chart.jpeg'}
                  alt="course-img"
                  style={{
                    marginRight: '10px',
                    width: '25px',
                    verticalAlign: 'sub',
                  }}
                />
                <span style={{fontSize: '1.2rem'}}>
                  <NavLink
                    to={`/tracking-system/${id}/levels/`}
                    style={{
                      color: '#fb5456',
                      fontWeight: '500',
                      textDecoration: 'underline',
                    }}>
                    Show Level Tree
                  </NavLink>
                </span>
              </div>

              <div>
                <h4>About System</h4>
                <p className="text-font-muted">{tracking?.description}</p>
              </div>

              <div>
                <h4>Fields</h4>

                <div className="mb-3">
                  {tracking?.fields.map((d, i) => (
                    <>
                      <p style={{margin: '10px 0 5px'}}>
                        <FaCircle color="fb5456" />
                        <span style={{marginLeft: '10px'}}>{d.name}</span>
                      </p>

                      {d.goals && (
                        <div className="goals" style={{marginLeft: '20px'}}>
                          {d.goals.map((goal, index) => {
                            return (
                              <div>
                                <FaCircle color="#9f9f9f" size={12} />
                                <span style={{marginLeft: '10px'}}>
                                  {goal.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div>
                {tracking?.is_enrolled ? (
                  <MainButton
                    text="Start Now"
                    onClick={() => setShowAssignUsers(true)}
                  />
                ) : (
                  ''
                )}
                {!tracking?.is_enrolled && tracking?.price == 0 ? (
                  <div onClick={() => setShow(true)}>
                    <MainButton text="Enroll Now" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Col>

          <Col md={6} className={classes.TrackingMedia}>
            <Card className={classes.TrackVideo}>
              <div className={classes.TrackImgContainer}>
                <div className={classes.CardImgContainer}>
                  {tracking?.intro_path_type == 'video' ? (
                    <>
                      <div className="video-player">
                        <video
                          style={{borderRadius: '15px 15px 0 0'}}
                          id="video"
                          src={tracking?.intro_path}
                          controls
                          autoPlay={isPlaying}
                          onClick={togglePlay}
                        />
                        {!isPlaying && (
                          <div className="overlay">
                            <img
                              src="/icons/play.png"
                              alt="Play Button"
                              onClick={togglePlay}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <img
                      className="card-img-top"
                      alt="courseImg"
                      src={tracking?.intro_path}
                    />
                  )}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {/*course preview pop up*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div
            className="pop-up-des"
            style={{
              padding: '2rem',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              borderRadius: '10px',
            }}>
            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">Follow-up system Name</div>
              <h5 className="desc-value">{tracking?.name}</h5>
            </div>

            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">Provided by</div>
              <h5 className="desc-value">{tracking?.provider_name}</h5>
            </div>

            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">duration</div>
              <h5 className="desc-value">{tracking?.duration}</h5>
            </div>

            <div className="desc-title-container mb-3">
              <div className="desc-title text-muted">Price</div>
              <h5 className="desc-value">{tracking?.price}</h5>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton text={'Enroll Now'} onClick={() => enroll()} />
          </div>

          <div onClick={() => setShow(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        <Modal.Header>
          <h5 className="secondry-color text-center mt-4">
            You can assign this follow-up system to you or any of your family
            members when starting it.
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.AuctionType}>
            <ul className="relative mb-3 w-full flex flex-wrap items-stretch justify-center p-0 hideScroll">
              {members.map((e, i) => (
                <li className="text-center" key={i}>
                  <input
                    type="radio"
                    name="test"
                    id={`name-${i}`}
                    value={e.id}
                    onChange={getAssignedUserVal}
                  />

                  <label htmlFor={`name-${i}`}>
                    <div>
                      <img
                        src={e.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '50px',
                          height: '50px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                    <h6>{e.username}</h6>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={'Start Now'}
              onClick={() => Assign()}
              disabled={member == null}
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserTrackingSystem;
