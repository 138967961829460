import Card from 'react-bootstrap/Card';
import {NavLink} from 'react-router-dom';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import {Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import { TrackingItemProps } from './interfaces/ITrackings';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import classes from './style/TrackingCard.module.css'
import {useTranslation} from 'react-i18next';


function InitiativeDetailsCard({tracking}: TrackingItemProps) {
  const {t} = useTranslation();
  return (
    <>
      <Card className="text-center">
        
        <Card.Body className="mt-3">
          <Card.Title>
            <div className="mb-3">{tracking?.name}</div>
          </Card.Title>

          
         
            
          <Card.Title>
            <div className="d-flex justify-content-center align-items-center">
              <span style={{fontSize: '1.2rem', margin: '0 5px'}}>
                {t('TotalScore')}
              </span>
              <span>
                
                
                  <CircleProgress
                    value={
                      tracking?.total_progress % 1 === 0
                        ? tracking?.total_progress.toFixed(0)
                        : tracking?.total_progress.toFixed(1)
                    }
                  />
                
              </span>
            </div>
          </Card.Title>
              
            
        


          <Card.Title>
              <div className="Date d-flex justify-content-center align-items-center pl-3 pr-3">
              <div className={`${classes.Start}`}>
                <p className="mb-1 m-0 d-flex align-items-center" style={{color: '#959494'}}>
                  <FaRegCalendarAlt style={{margin: '0 5px'}} /> {t('Start')}{' '}
                </p>
                <p>{tracking?.start_date}</p>
              </div>
              <div className={`${classes.ArrowDate}`}>
                <FaLongArrowAltRight color="#07b2bd" />
              </div>
              <div className={`${classes.End}`}>
                <p className="mb-1 m-0 d-flex align-items-center" style={{color: '#959494'}}>
                  <FaRegCalendarAlt style={{margin: '0 5px'}} /> {t('End')}
                </p>
                <p>{tracking?.end_date}</p>
              </div>
            </div>
              </Card.Title>

          
        </Card.Body>
      </Card>

      
    </>
  );
}

export default InitiativeDetailsCard;
